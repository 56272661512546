import styled, { css } from 'styled-components'

export const PageContainer = styled.div`
  padding: 0px 20px;
  column-gap: 10px;
  color: #666;
  > h3 {
    margin-bottom: 20px;
  }
  table thead tr th {
    padding: 15px 0px;
    font-weight: normal;
  }
  table tbody tr td {
    padding: 20px 0px;
  }
`

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 15px;

  border-radius: 10px;
  margin-bottom: 1em;

  > label {
    font-weight: normal;
  }

  select {
    width: 100%;
    padding: 5px;

    color: '#000';

    option:disabled {
      display: none;
    }
  }
`
export const Tr = styled.div<{ open: boolean; id: string }>`
  max-height: 60px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  ${({ open, id }) =>
    open &&
    css`
      max-height: ${document.getElementById(id)?.scrollHeight}px;
    `}
`
export const Avatar = styled.img<{ accepted?: string }>`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50% !important;
  margin-right: 8px;
  border: 2px solid ${props => props.accepted};
  :not(:first-child) {
    width: 24px;
    height: 24px;
    /* margin-left: -18px; */
  }
`

export const Card = styled.div<{
  small?: boolean
  color?: string
  active: boolean
}>`
  display: flex;
  flex-direction: column;
  min-height: ${({ small }) => (small ? '0px' : '120px')};

  padding: 10px;
  margin: 10px 0px;
  border-left: 4px solid ${({ color }) => color};
  background: ${({ active }) => (active ? '#ddd' : '#f2f2f2')};
  border-radius: 5px !important;
  cursor: pointer;

  footer {
    margin-top: auto;
    margin-left: auto;
    color: #888;
  }
  header,
  p > span {
    color: #aaa;
    font-style: italic;
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 6px !important;
  background: #fff;
`
export const Divider = styled.hr`
  margin: 20px 0px;
`

export const TabsContainer = styled.ul`
  display: flex;
  margin: 0;
  list-style: none;
  border-bottom: 1px solid #ddd;

  > li:last-child {
    margin-left: auto;
  }

  button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    color: #f2f2f2;
    background: #52b788;
    border-radius: 5px !important;
    font-size: 18px;
    font-weight: bold;
    vertical-align: middle;
  }
`
export const TabItem = styled.li<{ active: boolean }>`
  margin-bottom: -1px;
  padding: 1rem 2rem;
  border-radius: 4px 4px 0 0;
  border: ${({ active }) => (active ? '1px solid #fff' : 'none')};
  background: ${({ active }) => (active ? '#fff' : 'none')};
  border-bottom-color: #fff;

  cursor: pointer;
  > p:nth-child(1) {
    font-size: 18px;
  }
  > p:nth-child(2) {
    color: red;
  }
`
export const LinkContainer = styled.div`
  display: flex !important;
  justify-content: flex-end;
  color: #666;
  font-size: 14px;
  a {
    display: flex !important;
    align-items: center;
    margin: 0 3px;
    p {
      margin: 0 2px;
    }
  }
`

export const ReplyInput = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
`
